<template>
  <div class="auth-wrapper auth-v2">
    <div class="wrapper__app-bar-i18n">
      <app-bar-i18n></app-bar-i18n>
    </div>

    <div class="auth-inner">
      <GeneralLogo />

      <v-row class="auth-row ma-0">
        <v-col
          lg="8"
          class="d-none d-lg-block position-relative overflow-hidden pa-0"
        >
          <div class="auth-illustrator-wrapper">
            <!-- triangle bg -->
            <img
              height="362"
              class="auth-mask-bg"
              :src="
                require(`@/assets/images/misc/mask-v2-${
                  $vuetify.theme.dark ? 'dark' : 'light'
                }.png`)
              "
            />
          </div>
        </v-col>

        <v-col lg="4" class="d-flex align-center auth-bg pa-10 pb-0">
          <v-row>
            <v-col cols="12" sm="8" md="6" lg="12" class="mx-auto">
              <v-card flat>
                <v-card-text>
                  <p class="text-2xl font-weight-semibold text--primary mb-2">
                    {{ t("ForgotPass") }} 🔒
                  </p>
                </v-card-text>

                <!-- login form -->
                <v-card-text>
                  <v-form ref="forgotForm" @submit.prevent="handleFormSubmit">
                    <FormTextFieldPassword
                      v-model="formData.password"
                      :label="t('Password')"
                      :placeholder="t('Password')"
                      :rules="[validators.required]"
                      class="mb-6"
                    />

                    <FormTextFieldPassword
                      v-model="formData.re_password"
                      :label="t('RePassword')"
                      :placeholder="t('RePassword')"
                      :rules="[validators.required, passwordConfirmationRule]"
                      class="mb-6"
                    />

                    <v-btn
                      block
                      color="primary"
                      type="submit"
                      :loading="loading"
                      :disabled="loading"
                    >
                      {{ t("GetItBack") }}
                    </v-btn>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import GeneralLogo from "@/components/general/Logo";
import AppBarI18n from "@core/layouts/components/app-bar/AppBarI18n";
import FormTextFieldPassword from "@/components/forms/TextFieldPassword";
import {
  ref,
  reactive,
  onUpdated,
  onMounted,
  getCurrentInstance,
  computed,
} from "@vue/composition-api";
import { useUtils as useUtilsI18n } from "@core/libs/i18n";
import { required } from "@core/utils/validation";
import api from "@/services";
import { useRouter } from '@core/utils'

export default {
  components: {
    GeneralLogo,
    AppBarI18n,
    FormTextFieldPassword,
  },
  setup() {
    const { route } = useRouter();
    const vm = getCurrentInstance().proxy;
    const { t } = useUtilsI18n();
    const loading = ref(false);

    const forgotForm = ref(null);
    const formData = reactive({
      password: null,
      re_password: null,
      token: null
    });
    const forgotPassError = ref(t("ForgotPassError"));
    const forgotPassSuccess = ref(t("ForgotPassSuccess"));

    const passwordConfirmationRule = computed(() => {
      return () => (formData.password === formData.re_password) || ''
    });

    const onToggleLoading = () => {
      loading.value = !loading.value;
    };

    const handleFormSubmit = async () => {
      const isFormValid = forgotForm.value.validate();
      if (!isFormValid) return;

      onToggleLoading();

      try {
        await api.changePassword(formData);
        vm.$alert(forgotPassSuccess.value, null, "success");
        vm.$router.push("/");


      } catch (error) {
        vm.$alert(forgotPassError.value, null, "error");
      }

      onToggleLoading();
      forgotForm.value.reset();

    };

    onMounted(() => {
      const { token } = route.value.query;
      if(token) formData.token = token;
      else vm.$router.push("/");
    });

    onUpdated(() => {
      forgotPassError.value = t("ForgotPassError");
      forgotPassSuccess.value = t("ForgotPassSuccess");
    });

    return {
      forgotForm,
      formData,
      loading,

      validators: {
        required,
      },
      passwordConfirmationRule,

      handleFormSubmit,
      t,
    };
  },
};
</script>

<style lang="scss">
@import "@core/preset/preset/pages/auth.scss";
</style>
