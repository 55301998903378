<template>
  <!-- brand logo -->
  <router-link
    to="/"
    class="brand-logo d-flex align-center"
  >
    <v-img
      :src="appLogo"
      max-width="200px"
      alt="logo"
      contain
      class="me-3 "
    ></v-img>

    <!-- <h2 class="text--primary">
      {{ appName }}
    </h2> -->
  </router-link>
  <!--/ brand logo -->
</template>

<script>
import themeConfig from '@themeConfig'

export default {
  name: "GeneralLogo",

  setup() {
    return {
      // themeConfig
      appName: themeConfig.app.name,
      appLogo: themeConfig.app.logo,
    }
  }
}
</script>

<style type="scss" scoped></style>